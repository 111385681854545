
import packageInfo from '../../package.json';

export type ValidStage = 'stage' | 'development' | 'production' | 'local' | 'test';
export default {
	SHARED_DOMAIN: process.env.REACT_APP_SHARED_DOMAIN as string,
	KEMU_APP_DOMAIN: process.env.REACT_APP_KEMU_APP_DOMAIN as string,
	DASHBOARD_APP_DOMAIN: process.env.REACT_APP_DASHBOARD_DOMAIN as string,
	USER_API: process.env.REACT_APP_USER_API as string,
	THING_API: process.env.REACT_APP_THING_API as string,
	WIDGET_API: process.env.REACT_APP_WIDGET_API as string,
	ORGANIZATION_API: process.env.REACT_APP_ORGANIZATION_API as string,
	CUSTOMER_API: process.env.REACT_APP_CUSTOMER_API as string,
	TUTORIAL_API: process.env.REACT_APP_TUTORIAL_API as string,
	TUTORIAL_STATIC: process.env.REACT_APP_TUTORIAL_STATIC as string,
	RECIPE_API: process.env.REACT_APP_RECIPE_API as string,
	MARKETPLACE_API: process.env.REACT_APP_MARKETPLACE_API as string,
	MARKETPLACE_STATIC: process.env.REACT_APP_MARKETPLACE_STATIC as string,
	ACCESS_CODE_API: process.env.REACT_APP_ACCESS_CODE_API as string,
	WEB_SERVICES_API: process.env.REACT_APP_WEB_SERVICES_API as string,
	THING_STATIC_ASSETS: process.env.REACT_APP_THING_STATIC_ASSETS as string,
	WEBSOCKET_API: process.env.REACT_APP_WEBSOCKET_API as string,

	COGNITO_REGION: process.env.REACT_APP_COGNITO_REGION as string,
	COGNITO_POOL_ID: process.env.REACT_APP_COGNITO_POOL_ID as string,
	COGNITO_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID as string,

	USERBACK_ACCESS_TOKEN: process.env.REACT_APP_USERBACK_ACCESS_TOKEN as string,
	PENDO_API_KEY: process.env.REACT_APP_PENDO_API_KEY as string,
	FRESHCHAT_TOKEN: process.env.REACT_APP_FRESHCHAT_TOKEN_ID as string,
	FRESHCHAT_HOST: process.env.REACT_APP_FRESHCHAT_HOST as string,
	ASURAN_HOTSPOT_KEY: process.env.REACT_APP_ASURAN_HOTSPOT_KEY as string,

	STAGE: process.env.REACT_APP_STAGE as ValidStage,
	POSTHOG_PROJECT_API_TOKEN: process.env.REACT_APP_POSTHOG_PROJECT_API_TOKEN as string,
	HELP_HERO_APP_ID: process.env.REACT_APP_HELP_HERO_APP_ID as string,
	WEB_APP_VERSION: packageInfo.version,

	TYPESENSE_NODES: process.env.REACT_APP_TYPESENSE_NODES as string,

	AUTHENTICATED_API_NAME: 'AuthenticatedAPI',
	USER_API_NAME: 'UserAPI',
	WIDGET_API_NAME: 'WidgetAPI',
	ORGANIZATION_API_NAME: 'OrganizationAPI',
	CUSTOMER_API_NAME: 'CustomerAPI',
	TUTORIAL_API_NAME: 'TutorialAPI',
	TUTORIAL_STATIC_NAME: 'TutorialStatic',
	RECIPE_API_NAME: 'RecipeAPI',
	MARKETPLACE_API_NAME: 'MarketplaceAPI',
	THING_API_NAME: 'ThingAPI',
};
