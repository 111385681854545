import { Ace } from 'ace-builds';
import variablesManager from '@kemu-io/kemu-core/common/managers/variablesManager.js';
import { WidgetType, WidgetGroupState } from '@kemu-io/kemu-core/types';
import { VariableWidgetState } from '@kemu-io/kemu-core/widgets/variable/index.js';
import capitalize from 'capitalize';
import getBoundingBoxText from './completions/getBoundingBox';
import { getWidgetsInThing } from '@src/app/recipe/utils';
import { dataTypeToRawString } from '@common/utils';
import { getGlobalIntl } from '@src/assets/i18n/globalIntl';

type ScriptContext = {
	recipeId: string;
	thingRecipeId: string;
	widgetId: string;
}

let currentContext: ScriptContext | undefined;

export const setCompletionContext = (context: ScriptContext) => {
	currentContext = {
		...context,
	};
};

	// https://github.com/TouK/nussknacker/blob/v0.0.9/ui/client/components/graph/ExpressionSuggest.js#L31
export const customAceEditorCompleter: Ace.Completer = {
    getCompletions: (
			editor: Ace.Editor,
			session: Ace.EditSession,
			caretPosition2d: Ace.Point,
			prefix: string,
			callback: Ace.CompleterCallback
		) => {
			const completions: Ace.Completion[] = [];
			const keywords = ['getWidgetInputs', 'getWidgetOutputs', 'processEvent'];

			completions.push({
				caption: 'getVariable',
				snippet: `Kemu.variable.get('\${1:variableName}'\${2:/*, defaultValue */})`,
				docText: 'Reads the current value of a variable. Throws if the variable does not exist, and defaultValue is not provided.',
				meta: 'Kemu function',
				score: 100,
			});

			completions.push({
				caption: 'setVariable',
				snippet: `await Kemu.variable.set('\${1:variableName}', \${2:value})`,
				docText: 'Sets the value of a variable',
				meta: 'Kemu function',
				score: 100,
			});

			// Fill up list of variables
			if (currentContext) {
				const variables = variablesManager.getVariableNames(
					currentContext.recipeId,
					currentContext.thingRecipeId,
					currentContext.widgetId,
				);

				const thingWidgets = getWidgetsInThing(currentContext.recipeId, currentContext.thingRecipeId);
				const getWidgetById = (id: string) => thingWidgets[id];
				const t = getGlobalIntl();

				variables.forEach((variable) => {
					const owner = getWidgetById(variable.ownerWidgetId);
					const widgetName = owner.type === WidgetType.widgetGroup
						? (owner.state as WidgetGroupState).name
						: (owner.state as VariableWidgetState).name;

					const widgetType = capitalize(owner.type);
					const varType = dataTypeToRawString(t, variable.type);
					completions.push({
						value: variable.name,
						score: 101,
						meta: 'variable',
						caption: `${widgetName} -> ${variable.name}`,
						docText: `Variable "${variable.name}" comes from ${widgetType} "${widgetName}" (${varType})`,
					});
				});
			}

			keywords.forEach((word) => {
				if (word.startsWith(prefix)) {
					completions.push({
						value: word,
						score: 1,
						// TODO: 'Add documentation?
						meta: `Kemu function`,
					});
				}
			});

			completions.push({
				value: 'Kemu.services.http.request({ method: \'GET\', url: \'YOUR_URL\' }',
				score: 1,
				meta: 'Kemu HTTP service',
			});

			/**
			  getBoundingBox,
				getPixelValueAtIndex,
				copyImageData,
				setPixelValueAtIndex
			 */

			completions.push({
				value: 'const imageCopy = Kemu.image.copyImageData(imageData);',
				score: 1,
				meta: 'copyImageData',
			});

			completions.push({
				caption: 'decodeDataType',
				snippet: 'Kemu.helpers.decodeDataType(${1:object});',
				docText: 'Utility to determine the equivalent Kemu DataType at runtime',
				score: 1,
				meta: 'decodeDataType',
			});

			completions.push({
				value: `Kemu.image.setPixelValueAtIndex(
		imageData,
		0 /* index */, 
		{ /* RGB values or a number between 0 and 255 to set all 3 channels at once */ 
			r: 255,
			g: 255,
			b: 255,
		}
	);`,
				score: 1,
				meta: 'setPixelValueAtIndex',
			});

			completions.push({
				value: 'const { avr, r, g, b } = Kemu.image.getPixelValueAtIndex(imageData, index);',
				score: 1,
				meta: 'getPixelValueAtIndex',
			});

			completions.push({
				value: getBoundingBoxText(),
				score: 1,
				meta: 'getBoundingBox',
			});

		completions.push({
			value: `Kemu.variable.onValueChange('myVariable', async (value) => {
	console.log(\`Variable value changed to: \`, value);
});`,
			score: 1,
			docText: 'Subscribe to variable changes',
			meta: 'onValueChange',
		});

		completions.push({
			caption: 'sendToPort',
			snippet: `await sendToPort('\${1:portName}', {
	type: DataType.\${2:dataType},
	value: \${3:value},
})`,
			docText: 'Sends data to the given port name',
			meta: 'Kemu function',
			score: 100,
		});


// 			completions.push({
// 				value: `
// const requiredFields = {
// 	region: 'us-east-1',
// 	accessKey: 'YOUR_ACCESS_KEY,
// 	secretKey: 'YOUR_SECRET_KEY,
// 	tableName: 'Table ABC'
// };

// const optionalFields = {
// 	limit: 100,
// 	indexName: undefined,
// 	roleArn: undefined,
// 	externalId: undefined,
// 	indexName: undefined,
// 	lastKey: undefined,
// 	filterExpression: undefined,
// 	// projectionExpression: '#id, #name',
// 	// expressionAttributeNames: { '#name': 'name', '#id': 'id' },
// 	lastKey: undefined,
// }

// const result = await Kemu.services.dynamoDb.scan({ 
// 	...requiredFields,
// 	...optionalFields,	
// });

// if(result.success){
// 	console.log(\`Results: \${JSON.stringify(result.data)}\`);
// }else{
// 	console.log(\`Failed to fetch results: \${result.data}\`);
// }
// 				`,
// 				score: 1,
// 				meta: 'Kemu Dynamo service',
// 			});

			callback(null, completions);
      // const suggestions = this.expressionSuggester.suggestionsFor(this.state.value, caretPosition2d)
      // callback(null, suggestions?.map((s: any) => {
      //   //unfortunately Ace treats `#` as special case, we have to remove `#` from suggestions or it will be duplicated
      //   //maybe it depends on language mode?
      //   const methodName = s.methodName.replace("#", "")
      //   const returnType = ProcessUtils.humanReadableType(s.refClazz)
      //   return {
			// 		name: methodName, 
			// 		value: methodName, 
			// 		score: 1,
			// 		meta: returnType, 
			// 		description: s.description, 
			// 		parameters: s.parameters, 
			// 		returnType: returnType
			// 	}
      // }))
    },
    getDocTooltip: (item: any) => {
			return undefined;
			// console.log('Tooltip for item ', item);
      // if (item?.description || !item?.parameters) {
      //   const paramsSignature = item.parameters.map(p => ProcessUtils.humanReadableType(p.refClazz) + " " + p.name).join(", ")
      //   const javaStyleSignature = `${item.returnType} ${item.name}(${paramsSignature})`
      //   item.docHTML = ReactDOMServer.renderToStaticMarkup((
      //     <div className="function-docs">
      //       <b>{javaStyleSignature}</b>
      //       <hr/>
      //       <p>{item.description}</p>
      //     </div>
      //   ))
      // }
    }
  };
