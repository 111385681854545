import { useCallback, useMemo } from 'react';
import { SearchClient } from 'typesense';
import { useSelector } from 'react-redux';
import globals, { ValidStage } from '@common/globals';
import { selectSignedUserProfile } from '@src/app/reducers/user/userSlice';

const nodes = globals.TYPESENSE_NODES.split(',').map(url => ({ url }));

type SearchConfig = {
  indexName?: string;
  queryBy?: string;
  perPage?: number;
  page?: number;
  filterBy?: string;
  sortBy?: string;
  facetBy?: string;
  noCache?: boolean;
  /**
   * The query to search for. If not provided, the default is `*`.
   */
  query?: string;
};

export type SearchResult<T> = {
  documents: T[];
  total: number;
  page: number;
  pages: number;
};

const stageSuffixMap: Record<ValidStage, string> = {
  'stage': 'stage',
  'development': 'dev',
  'production': 'prod',
  'local': 'dev',
  'test': 'test',
};

const getSuffixedCollection = (indexName: string) => {
  return `${indexName}_${stageSuffixMap[globals.STAGE]}`;
};

const useMultiIndexSearch = (defaultCollection?: string) => {
  const { tokens } = useSelector(selectSignedUserProfile);

  const searchClient = useMemo(() => {
    if (!tokens) { return null; }

    const client = new SearchClient({
      apiKey: tokens.search,
      nodes,
      cacheSearchResultsForSeconds: 10, // cache for 30 seconds
    });

    return client;
  }, [tokens]);

  const searchByIndex = useCallback(async <T extends Record<string, any>>(config: SearchConfig): Promise<SearchResult<T>> => {
    if (!searchClient) { return { documents: [], total: 0, page: 0, pages: 0 }; }
    if (!config?.indexName && !defaultCollection) {
      throw new Error('No index name provided');
    }

    const collection = getSuffixedCollection((config.indexName || defaultCollection) as string);
    // const response = await searchClient.collections(collection).documents().search({
    //   q: '*',
    //   filter_by: config.filterBy,
    // }, {});

    // const results = [response];
    const { results } = await searchClient?.multiSearch.perform<T[]>({
      searches: [{
        collection,
        q: config.query || '*',
        query_by: config.queryBy,
        per_page: config.perPage,
        page: config.page,
        filter_by: config.filterBy,
        sort_by: config.sortBy,
        facet_by: config.facetBy,
        use_cache: !config.noCache,
      }],
    });

    const documents = (results[0].hits ?? []).map(hit => hit.document) as T[];
    const total = results[0].found;
    const page = results[0].page;
    const pages = Math.ceil(total / (results[0].request_params.per_page ?? 1));
    return {
      documents,
      total,
      page,
      pages,
    };
  }, [searchClient, defaultCollection]);

  const memoizedInstance = useMemo(() => ({
    searchByIndex,
  }), [searchByIndex]);

  return memoizedInstance;
};

export default useMultiIndexSearch;
